import React, { useRef, useEffect } from "react"
import Section from "./section";
import githubIcon from "../images/icons/github.png";
import linkedInIcon from "../images/icons/linkedin.png";

const LinkIcon = ({ href, imgSrc, desc }) => (
  <div className="flex flex-col space-x-1">
    <a href={href} target="blank">
      <img src={imgSrc} alt={desc} className="h-16" />
      <div>{desc}</div>
    </a>
  </div>
)

const About = () => {
  const emailLinkRef = useRef();
  useEffect(() => {
    emailLinkRef.current.href = 'mailto:contact@abhirao.dev';
    emailLinkRef.current.innerHTML = 'contact@abhirao.dev';
  }, [emailLinkRef]);
  return (
    <Section id="about" className="flex flex-row space-x-10">
      <div className="print:flex print:flex-row print:w-full print:text-xl print:text-center print:justify-center print:font-semibold print:divide-x-2 print:divide-dashed print:divide-black print:gap-x-3 flex flex-col justify-between">
        <h1 className="m-0">Abhi Rao</h1>
        <h2 className="print:pl-3 mt-0">Houston, TX</h2>
        <a ref={emailLinkRef} href="email" className="print:pl-3 mt-0">contact at abhirao.dev</a>
      </div>
      <div className="flex flex-row space-x-2 print:hidden">
        <LinkIcon href="https://www.linkedin.com/in/abhi-rao" imgSrc={linkedInIcon} desc="LinkedIn" />
        <LinkIcon href="https://github.com/abhirao" imgSrc={githubIcon} desc="GitHub" />
      </div>
    </Section>
  );
}

export default About;