import React from "react";
import Section, { SectionTitle } from "./section";

export default function Education() {
  return (
    <Section id="education">
      <SectionTitle>Education</SectionTitle>
      <div className="flex justify-between">
        <h2>University of Houston</h2>
        <h3>2002-2006</h3>
      </div>
      <ul className="list-disc list-inside">
        <li>B.S., Computer Science. Minor in Mathematics and Philosophy</li>
      </ul>
    </Section>
  )
}