import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about";
import Skills from "../components/skills";
import Experience from "../components/experience";
import VolunteerWork from "../components/volunteerWork";
import Education from "../components/education";
import '../components/styles.css';

const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="Resume" />
    <About />
    <Skills />
    <Experience />
    <VolunteerWork />
    <Education />
    <div className="grid-cols-1 lg:grid-cols-6 print:hidden">
      © {new Date().getFullYear()} Abhijeet Rao, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
      </div>
  </Layout>
)

export default IndexPage
