import React from "react";
import Section, { SectionTitle } from "./section";

export function Job({ children, title, organization : { name , link }, tenure: { start, end="Present" }}) {
  return (
    <div className="">
      <div className="flex w-full justify-between print:p-0 print:text-black p-4 mb-4 text-white bg-blue-700 sm:sticky sm:top-0">
        <div className="">
          <h2 className="text-xl">
            {
              link 
              ? <a href={link} target="blank">{name}</a>
              : name
            }
          </h2>
          {title && <h3 className="text-lg italic">{title}</h3>}
        </div>
        <h3 className="text-xl">{start} - {end}</h3>
      </div>
      {children}
      <div className="pb-24 print:hidden"/>
    </div>
  )
}

export function Responsibilities({ children }) {
  return (
    <>
      <h3 className="text-lg font-medium">Responsibilities</h3>
      <ul className="list-disc list-inside pb-6">
        {children}
      </ul>
    </>
  );
}

function Projects({ children }) {
  return (
    <>
      <h3 className="text-lg font-medium pb-2">Projects</h3>
      <div className="space-y-4">
        {children}
      </div>
    </>
  );
}

function Project({ title, children }) {
  return (
    <section className="">
      <h3 className="text-lg">{title}</h3>
      {children}
    </section>
  )
}

export function Overview({ children }) {
  return (
    <>
      <h3 className="text-lg font-medium">Overview</h3>
      <p className="pb-4">
        {children}
      </p>
    </>
  )
}

export default function Experience() {
  return  (
    <Section id="experience" className="space-y-2">
      <SectionTitle>Experience</SectionTitle>
      <div className="space-y-4">
        <Job
          title="Senior Software Architect"
          organization={{ name: "Onit, Inc.", link: "https://www.onit.com"}}
          tenure={{ start: "May 2012" }}
        >
          <Overview>
            Onit is a provider of workflow solutions in the LegalTech space. Joined the startup as one of the first dozen employees. Key contributor to the platform that grew ARR from &lt;1M to &gt;40+M. Provided technical leadership and mentorship to new developers. Worked closely with Product Management to understand how features can be added to a complex platform without impacting existing customers.
          </Overview>
          <Responsibilities>
            <li>Technical leadership</li>
            <li>Merge reviews</li>
            <li>Security Evaluations</li>
            <li>Performance Improvements</li>
          </Responsibilities>
          <Projects>
            <Project title="Initial Platform Buildout">
              <p>Worked on the initial buildout of the workflow platform (using Ruby on Rails) that would be the basis of Onit's product offerings. The platform needed to be powerful enough to solve any problem involving approval and collaboration but unopinionated so that any customer's business process could be modeled with configuration but no code change. </p>
            </Project>
            <Project title="Data migration from MongoDB to PostGreSQL">
              <p>While the schema-less approach of MongoDB made it easy to get started, the lack of relationships and cross-document transactions were a severe limitation. Designed a migration process where data from MongoDB could be moved to PostGreSQL seamlessly. The data scaled up to millions of rows and had the ability to migrate customers with minimal downtime (minutes, not hours).</p>
            </Project>
            <Project title="Front-end Rewrite using React">
              <p>The level of complexity of the application led to many performance issues and bugs in the prior UI framework. Championed React as the framework for a major UI rewrite in 2016. React's declarative model and one way data flow led to a more stable application and better isolation of responsibilities. </p>
            </Project>
          </Projects>
        </Job>
        <Job
          title="Senior Developer"
          organization={{ name: "J.P. Morgan Chase" }}
          tenure={{ start: "May 2006", end: "May 2012" }}
        >
          <Overview>
            Joined the firm as an intern during final year in university. Quickly became an integral part of the team. Learned all parts of application stack and business domain of application. Became technical lead for the team after two years.
          </Overview>
          <Responsibilities>
            <li>Implemented changes from front to back end. Worked on Web, UI, Batch applications, DB changes and APIs</li>
            <li>Designed changes to address business needs and non-functional requirements</li>
            <li>Responsible for management of SVN repo and maven build</li>
          </Responsibilities>
          <Projects>
            <Project title="Approvals Recording and Control">
            <p>ARC was a J2EE web application with 4000+ internal users across the globe. It was used across LOBs to grade clients and approve credit facilities and transactions. All proposed changes would go through a workflow where required approvals were calculated and recorded. It utilized EJBs and MDBs and had interfaces with several applications. It used Spring, Hibernate and Struts.</p>
            </Project>
            <Project title="Introduction of Letters of Credit to ARC">
              <p>
                A new workflow was added to allow users to approve Letters of Credit via ARC. LC requests would come in through a new MQ interface with a mainframe application based in Hong Kong.
                Worked with architect to design application changes.
                Responsible for integration between Websphere MQ and Weblogic.
                Designed and implemented a fixed-length message parser.
                Other changes included workflow, UI and timed jobs for notifications and automated rejections of eligible LCs.
              </p>
            </Project>
            <Project title="Reengineering of ARC">
              <p>To improve the application performance as experienced by users ARC was split into two - foreground and background. All UI interaction was encapsulated in the foreground app. Any long running tasks which could be performed asynchronously were moved to a new background application. 
              Responsible for moving various workflows from a UI based synchronous model to asynchronous background tasks.
              Refactored so that foreground and background components did not duplicate any code. 
              </p>
            </Project>
            <Project title="Onboarding Asia/EMEA regions for Letters of Credit Approval">
              <p>Two years after initial LC implementation, Asia and EMEA regions needed to be onboarded. These regions required some changes to the workflow but the challenge was to reuse the code base and triple the load without impacting the user experience.
              Designed application changes and directed the offshore team that implemented the changes.
              Designed region handling so that addition of new processing units would require some database configuration but no new application code.
              </p>
            </Project>
          </Projects>
        </Job>
      </div>
    </Section>
  );
}
 