import React from "react"

export function SectionTitle({ children }) {
  return (
    <h1 className="text-4xl pb-4">{children}</h1>
  )
}

export default function Section({ id, children, className="" }) {
  return (
    <section id={id} className={`p-4 print:pb-0 pb-8 w-full ${className}`}>
      {children}
    </section>
  );
}