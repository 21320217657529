import React from "react";
import Section, { SectionTitle } from "./section";
import rubyIcon from "../images/icons/ruby.jpg";
import railsIcon from "../images/icons/rails.png";
import postgresIcon from "../images/icons/postgres.png";
import reactIcon from "../images/icons/react.svg";
import jsIcon from "../images/icons/javascript.png";
import gitIcon from "../images/icons/git.png";
import mongoIcon from "../images/icons/mongodb.png";

function Skill({ title, icon }) {
  return (
    <li className="flex flex-col justify-between h-28 w-20 print:h-auto print:w-full print:list-item print:list-disc print:list-inside">
      <img src={icon} alt={title} className="print:hidden" />
      <span className="text-center font-medium print:text-left">{title}</span>
    </li>
  )
}

function SoftSkill({ title }) {
  return (
    <li className="w-32 text-center font-medium print:h-auto print:w-full print:list-item print:list-disc print:list-inside print:text-left">
      {title}
    </li>
  )
}

export default function Skills() {
  return (
    <Section id="skills">
      <h1 className="text-4xl">Skills</h1>
      <h1 className="text-lg m-4">Technologies</h1>
      <ul className="print:grid print:grid-cols-4 print:gap-1 inline-flex gap-8 m-4">
        <Skill title="Ruby" icon={rubyIcon} />
        <Skill title="Rails" icon={railsIcon} />
        <Skill title="MongoDB" icon={mongoIcon} />
        <Skill title="PostGreSQL" icon={postgresIcon} />
        <Skill title="JavaScript" icon={jsIcon} />
        <Skill title="React" icon={reactIcon} />
        <Skill title="Git" icon={gitIcon} />
      </ul>
      {/* <h1 className="text-lg m-4">Soft Skills</h1>
      <ul className="print:grid print:grid-cols-4 print:gap-1 inline-flex list-disc gap-8 m-4 pl-4">
        <SoftSkill title="Code Reviews"/>
        <SoftSkill title="Mentorship"/>
      </ul> */}
    </Section>
  );
}