import React from "react";
import Section, { SectionTitle } from "./section";
import { Responsibilities, Job as VolunteerProject, Overview } from "./experience";

export default function VolunteerWork() {
  return (
    <Section id="volunteer_work" className="space-y-2">
      <SectionTitle>Volunteer Work</SectionTitle>
      <VolunteerProject
        organization={{ name: "Relief Toolkit", link: "https://relieftoolkit.com" }}
        tenure={{ start: "2019" }}
      >
        <Overview>
          The Grassroots Disaster Relief Toolkit is a collection of resources made to support communities organizing mutual aid-based disaster relief.
        </Overview>
        <Responsibilities>
          <li>Helped choose technologies and organize team</li>
          <li>Implemented features from back-end to front-end</li>
          <li>Styled sections of site based on designs</li>
        </Responsibilities>
      </VolunteerProject>
    </Section>
  )
}